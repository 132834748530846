import React from "react"
import styles from "./situation.module.css"

export default ({
  confirmed,
  deaths,
  recovered,
  deathsIncrease,
  confirmedIncrease,
  recoveredIncrease,
  style,
}) => {
  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.item}>
        <div className={styles.label}>Confirmed</div>
        <div className={`${styles.value} ${styles.confirmed}`}>
          +{confirmedIncrease}
        </div>
        <div className={`${styles.subValue} ${styles.confirmed}`}>
          {confirmed} total
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Deaths</div>
        <div className={`${styles.value} ${styles.deaths}`}>
          +{deathsIncrease}
        </div>
        <div className={`${styles.subValue} ${styles.deaths}`}>
          {deaths} total
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>Recovered</div>
        <div className={`${styles.value} ${styles.recovered}`}>
          +{recoveredIncrease}
        </div>
        <div className={`${styles.subValue} ${styles.recovered}`}>
          {recovered} total
        </div>
      </div>
    </div>
  )
}
